import store from "@/state/store";

export default [

    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({name: "default"});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },

    {
        name: 'dashboard',
        path: '/',
        component: () => import('../views/dashboard/Index.vue'),
        meta: {
            title: 'Dashboard',
            authRequired: true,
        },
    },

    {
        name: 'new_application',
        path: '/application/new/',
        component: () => import('../views/applications/Create.vue'),
        meta: {
            title: 'New Application',
            authRequired: true,
        },
    },

    {
        name: 'update_application',
        path: '/application/:application_id/edit/',
        component: () => import('../views/applications/Update.vue'),
        meta: {
            title: 'Edit Application',
            authRequired: true,
        },
    },

    {
        name: 'codes',
        path: '/codes/',
        component: () => import('../views/codes/Index.vue'),
        meta: {
            title: 'Codes List',
            authRequired: true,
        },
    },

    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Logout", authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                sessionStorage.clear();
                next();
            },
        },
        component: () => import("../views/auth/logout/basic")
    },

];