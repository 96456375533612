import {createApp} from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";
import axios from "axios";
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';
import FloatingVue, {VTooltip} from 'floating-vue'

import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/material/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import 'floating-vue/dist/style.css'

axios.defaults.baseURL = process.env.VUE_APP_ORDER_URL

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

FloatingVue.options.themes.tooltip.delay.show = 0
FloatingVue.options.themes.tooltip.delay.hide = 10
FloatingVue.options.instantMove = true

createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .directive('tooltip', VTooltip)
    .use(Maska)
    .use(Particles)
    .use(i18n)
    .use(FloatingVue)
    .use(vClickOutside).mount('#app');