import {createWebHistory, createRouter} from "vue-router";
import axios from 'axios';
import routes from './routes';
import store from "@/state/store";

const router = createRouter({
    history: createWebHistory(),
    routes,

    mode: 'history',

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0, left: 0};
        }
    },
});


router.beforeEach(async (to, from, next) => {
    const authRequired = to.matched.some(route => route.meta.authRequired);

    if (!authRequired) return next();

    const token = localStorage.getItem('jwt');

    if (!token) {
        return next({name: 'login', query: {redirectFrom: to.fullPath}});
    }

    axios.defaults.headers.common['authorization'] = 'Bearer ' + token;

    try {
        const userData = await verifyToken(token);
        await store.dispatch('auth/setCurrentUser', userData, {root: true});
        next();
    } catch (error) {
        if (error.response && error.response.status === 401) {
            try {
                const newToken = await refreshToken();
                axios.defaults.headers.common['authorization'] = 'Bearer ' + newToken;
                const userData = await verifyToken(newToken);
                await store.dispatch('auth/setCurrentUser', userData, {root: true});
                next();
            } catch (refreshError) {
                next({name: 'login', query: {redirectFrom: to.fullPath}});
            }
        } else {
            next({name: 'login', query: {redirectFrom: to.fullPath}});
        }
    }
});
router.beforeResolve(async (routeTo, routeFrom, next) => {
    document.title = (routeTo.meta.title || '') + ' | InterRail'
    next();
});


async function verifyToken() {
    // eslint-disable-next-line no-useless-catch
    try {
        const response = await axios.post('user/verify/');
        return response.data;
    } catch (error) {
        throw error;
    }
}

async function refreshToken() {
    // eslint-disable-next-line no-useless-catch
    try {
        const refreshResponse = await axios.post('user/refresh/', {
            refresh: localStorage.getItem('refresh') || '',
        });
        const newToken = refreshResponse.data.access;
        localStorage.setItem('jwt', newToken);
        return newToken;
    } catch (error) {
        throw error;
    }
}

export default router;
