import axios from "axios";

export const state = {
    currentUser: sessionStorage.getItem('authUser'),

    login_token: null,
    errors: {},
    is_trying_to_login: false,
    user: {}
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.user = newValue.user
        state.login_token = newValue.access
    },
    SET_LOGIN_TOKEN(state, newValue) {
        state.errors = {}
        if (newValue) {
            state.login_token = newValue.access
            localStorage.setItem('jwt', newValue.access)
            localStorage.setItem('refresh', newValue.refresh)
        } else {
            localStorage.removeItem('jwt')
            localStorage.removeItem('refresh')
        }
    },
    SET_ERRORS(state, errors) {
        state.errors = errors
    },
    SET_IS_TRYING_TO_LOGIN(state, is_trying_to_login) {
        state.is_trying_to_login = is_trying_to_login
    },
}

export const getters = {
    loggedIn(state) {
        return !!state.currentUser
    },
    login_token(state) {
        return state.login_token
    },
    loggedUser(state) {
        return state.user
    }
}

export const actions = {
    async logIn({commit}, {username, password} = {}) {
        commit('SET_IS_TRYING_TO_LOGIN', true)
        try {
            let response = await axios.post("user/login/",
                {
                    username: username,
                    password: password,
                }, {
                    timeout: 1000,
                }
            )
            if (response?.data?.access) {
                commit('SET_LOGIN_TOKEN', {
                    access: response.data.access,
                    refresh: response.data.refresh
                })
            } else {
                commit('SET_LOGIN_TOKEN', undefined)
                commit('SET_ERRORS', response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            commit('SET_LOGIN_TOKEN', undefined)
            commit('SET_ERRORS', {
                message: error.response?.data?.message || 'Something went wrong'
            })
        }
        commit('SET_IS_TRYING_TO_LOGIN', false)
    },

    logOut({commit}) {
        commit('SET_CURRENT_USER', undefined)
        commit('SET_LOGIN_TOKEN', undefined)
    },

    setCurrentUser({commit}, user) {
        commit('SET_CURRENT_USER', user)
    },
    setLoginToken({commit}, token) {
        commit('SET_LOGIN_TOKEN', token)
    }
}

// ===
// Private helpers
// ===

// function saveState(key, state) {
//     window.sessionStorage.setItem(key, JSON.stringify(state))
// }
